<template>
  <BAlert v-bind:fade="fade" v-model="show" v-bind:variant="variant">
    <ul v-if="Array.isArray(message) && message.length" class="m-0 pl-3">
      <li v-for="(item, index) in message" v-bind:key="index">
        {{ item }}
      </li>
    </ul>
    <div v-else>{{ message }}</div>
  </BAlert>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("alert", {
      fade: state => state.fade,
      message: state => state.message,
      variant: state => state.variant
    }),
    show: {
      get() {
        return this.$store.state.alert.show;
      },
      set(value) {
        this.$store.commit("alert/setShow", value);
      }
    }
  }
};
</script>
