<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-bind:width="size" v-bind:height="size">
    <path
      fill="#ffffff"
      d="M256 512C114.43 512 0 397.57 0 256C0 114.43 114.43 0 256 0C397.57 0 512 114.43 512 256C512 397.57 397.57 512 256 512Z"
    />
    <path
      fill-rule="evenodd"
      fill="#30363d"
      d="M420.05 92.29C427.63 149.76 389.26 239.92 331.49 296.5C331.89 297.57 332.3 298.53 332.69 299.76C344.26 361.16 270.92 384.93 234.25 396.82C240.22 379.37 246.22 361.93 253.13 344.44C247.06 346.04 240.92 347.18 234.74 347.8C187.98 325.78 181.02 316.72 162.37 273.53C163.06 267.74 164.22 262 165.76 256.32C147.92 261.94 130.74 267.49 113.63 273.04C126.18 236.4 151.28 163.11 212.08 175.98C213.3 176.41 214.23 176.83 215.3 177.25C267.31 125.63 346.42 90.59 403.2 91C409.08 91.04 414.71 91.46 420.05 92.29ZM335.95 139.84C335.05 139.81 334.14 139.81 333.23 139.85C332.32 139.89 331.42 139.96 330.51 140.07C329.61 140.18 328.72 140.32 327.82 140.5C326.93 140.67 326.05 140.88 325.17 141.12C320.57 142.39 316.27 144.56 312.5 147.5C308.73 150.45 305.58 154.11 303.22 158.28C300.86 162.45 299.34 167.05 298.75 171.81C298.15 176.57 298.5 181.4 299.76 186.03C301.02 190.65 303.18 194.98 306.1 198.77C309.03 202.56 312.67 205.73 316.82 208.11C320.96 210.48 325.53 212.01 330.26 212.61C335 213.21 339.8 212.86 344.4 211.59C349 210.32 353.3 208.16 357.07 205.21C360.84 202.27 363.99 198.61 366.35 194.44C368.72 190.27 370.24 185.68 370.83 180.92C371.43 176.16 371.09 171.33 369.83 166.7L369.81 166.67C369.43 165.28 368.98 163.93 368.44 162.6C367.9 161.27 367.29 159.97 366.6 158.72C365.92 157.46 365.15 156.25 364.33 155.08C363.5 153.92 362.6 152.8 361.64 151.74L361.64 151.75C360.01 149.95 358.2 148.32 356.25 146.88C354.29 145.44 352.2 144.2 350 143.18C347.8 142.16 345.5 141.36 343.14 140.8C340.78 140.24 338.37 139.92 335.95 139.84L335.95 139.84ZM164.76 322.31C169.95 322.29 175.12 324.28 180.16 328.86C220.46 365.45 91 421 91 421C91 421 128.41 322.43 164.76 322.31Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      required: false,
      default: 512
    }
  }
};
</script>
